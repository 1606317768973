export const list = [
  {
    id: 1,
    header: "Kurumsal",
    links: [
      {
        id: 10,
        label: "Hakkımızda",
        slug: "hakkimizda",
      },
      {
        id: 20,
        label: "İnsan Kaynakları",
        slug: "insan-kaynaklari",
      },
      {
        id: 30,
        label: "Haberler",
        slug: "haberler",
      },
      {
        id: 40,
        label: "İletişim",
        slug: "iletisim",
      },
    ],
  },
  {
    id: 2,
    header: "Gizlilik ve Kullanım",
    links: [
      {
        id: 11,
        label: "Üyelik Sözleşmesi",
        slug: "uyelik-sozlesmesi",
      },
      {
        id: 12,
        label: "Teslimat ve İade Şartları",
        slug: "teslimat-ve-iade-sartlari",
      },
      {
        id: 13,
        label: "Alıcıların Merak Ettikleri",
        slug: "alicilarin-merak-ettikleri",
      },
      {
        id: 14,
        label: "KVKK",
        slug: "kisisel-verilerin-korunmasi-kanunu",
      },
      // {
      //   id: 5,
      //   label: "Satıcıların Merak Ettikleri",
      //   link: "saticilarin-merak-ettikleri",
      // },
    ],
  },
  {
    id: 3,
    header: "Yardım",
    links: [
      {
        id: 31,
        label: "Neden Alıcılar?",
        slug: "neden-alicilar",
      },
      {
        id: 32,
        label: "Nasıl Çalışır?",
        slug: "nasil-calisir",
      },
      {
        id: 33,
        label: "Üye Olmak İstiyorum",
        slug: "uye-olmak-istiyorum",
      },
      {
        id: 34,
        label: "Sıkça Sorulan Sorular",
        slug: "sikca-sorulan-sorular",
      },
    ],
  },
];
