import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import FilterSidebar from "../../../FilterSidebar";
import { connect } from "react-redux";
import * as categoryRedux from "../../../_redux/categoryRedux";
import * as advertRedux from "../../../../Advert/_redux/advertRedux";
import PriceFilter from "../../../../FilterComponents/PriceFilter";
import TextField from "@material-ui/core/TextField";
import { convertPrice } from "../../../../../helpers/PriceHelper";
import SelectSection from "../SelectSection";

const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
  drawerHeader: {
    fontSize: "18px",
    margin: 0,
    paddingTop: "15px",
    letterSpacing: "0.2px",
  },
  applyButton: {
    background: "rgb(17, 152, 110)",
    color: "#efefef",
    borderRadius: 4,
    border: "1px solid rgb(17, 152, 110)",
    fontSize: 15,
    cursor: "pointer",
    width: "100%",
    marginTop: 15,
    padding: "3px 0",
  },
  clearPriceFilterButton: {
    background: "transparent",
    outline: "none",
    border: 0,
    cursor: "pointer",
    height: "100%",
    fontSize: 28,
  },
  filterIconButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 25,
  },
});

function DrawerMenu(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const [prices, setPrices] = React.useState({
    maximumPrice: 0,
    minimumPrice: 0,
  });

  useEffect(() => {
    if (
      Object.keys(props.selectedFilter).some(
        (filter) => filter == "minMaxFiyat"
      )
    ) {
      setPrices({
        minimumPrice: props.selectedFilter.minMaxFiyat[0],
        maximumPrice: props.selectedFilter.minMaxFiyat[1],
      });
    } else {
      setPrices({
        minimumPrice: "",
        maximumPrice: "",
      });
    }
  }, [props.selectedFilter]);

  const { adverts } = props;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSearchButton = () => {
    if (
      Number(prices.maximumPrice) < 0 ||
      Number(prices.minimumPrice) < 0 ||
      Number(prices.maximumPrice) < Number(prices.minimumPrice)
    )
      return;

    if (Number(prices.maximumPrice) == 0 && Number(prices.minimumPrice) == 0) {
      props.removeFilter({ parent: "minMaxFiyat" });
    } else {
      props.addFilterBulk({
        parent: "minMaxFiyat",
        value: [Number(prices.minimumPrice), Number(prices.maximumPrice)],
      });
    }

    setState({ ...state, left: false });
  };

  const removeMinMaxPriceFilter = () => {
    props.removeFilter({ parent: "minMaxFiyat" });
    setPrices({ minimumPrice: "", maximumPrice: "" });
  };

  const changeHandler = (e) => {
    setPrices({
      ...prices,
      [e.target.name]: e.target.value,
    });
  };

  const list = (anchor, provinces) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className="d-flex justify-content-center align-items-center">
        <p className={classes.drawerHeader}>Filtrele</p>
      </div>
      <hr />
      <div className="pl-2 pr-2">
        <SelectSection
          filter={{
            filterType: "singleSelect",
            values: provinces?.map((province) => province.name),
            title: "İl",
            slug: "province",
          }}
          key={"province"}
          extraData={provinces}
        />
        {props?.selectedFilter?.location?.province ? (
          <SelectSection
            filter={{
              filterType: "singleSelect",
              values: provinces
                ?.find(
                  (province) =>
                    province.name ==
                    props.selectedFilter?.location?.province?.name
                )
                ?.districts?.map((district) => district.name),
              title: "İlçe",
              slug: "district",
            }}
            key={"district"}
            extraData={provinces}
          />
        ) : null}

        <FilterSidebar filters={props.filters} />
      </div>
      <div className="pl-2 pr-2 mr-1">
        <div className="row d-flex">
          <div className="col-6 mt-3">
            <TextField
              id="outlined-password-input"
              label="Min TL"
              name="minimumPrice"
              value={prices.minimumPrice}
              type="number"
              autoComplete="current-password"
              variant="outlined"
              size="small"
              onChange={(e) => changeHandler(e)}
            />
          </div>
          <div className="col-6 mt-3 d-flex">
            <TextField
              id="outlined-password-input"
              label="Maks TL"
              name="maximumPrice"
              value={prices.maximumPrice}
              type="number"
              autoComplete="current-password"
              variant="outlined"
              size="small"
              onChange={(e) => changeHandler(e)}
            />

            {Object.keys(props.selectedFilter).some(
              (currentFilter) => currentFilter == "minMaxFiyat"
            ) ? (
              <button
                className={classes.clearPriceFilterButton}
                onClick={removeMinMaxPriceFilter}
              >
                &times;
              </button>
            ) : null}
          </div>
        </div>

        <button className={classes.applyButton} onClick={handleSearchButton}>
          <span>Ara</span>
        </button>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column align-items-center">
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            onClick={toggleDrawer(anchor, true)}
            className={classes.filterIconButton}
          >
            {props.width <= 768 && (
              <i className="fa fa-filter" style={{ color: "#11986E" }} />
            )}
          </div>
          {/*adverts.length > 0 && props.width > 768 && (
            <Button
              style={{ fontSize: "10px" }}
              onClick={toggleDrawer(anchor, true)}
            >
              Daha Fazla
            </Button>
          )*/}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor, props.provinces)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  adverts: state.advert.advertsOfCategory.results,
  selectedFilter: state.category.selectedFilter,
});

export default connect(mapStateToProps, {
  ...advertRedux.actions,
  ...categoryRedux.actions,
})(DrawerMenu);
